import Tweener from '../modules/Tweener';

class TweenerTitle extends Tweener {
  public addTweens() {
    super.addTweens(); // Inherit methods form parent class.
    const delay = 0.07;
    // const speed = 0;
    const speed = 0.03;
    [].forEach.call(this.$tweener.children, ($line: any) => {
      $line.innerHTML = $line.innerText
        .split('')
        .reduce((html, l) => `${html}<span class="line__letter">${l}</span>`, '');
      [].forEach.call($line.children, ($letter: any) => {
        this.addTween($letter, speed, { opacity: 0 }, `+=${delay}`);
      });
    });
    return this;
  }
}

export default TweenerTitle;
