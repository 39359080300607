import scrollmonitor from 'scrollmonitor';

import Tweener from './Tweener';
import TweenerAbout from './Tweener.About';
import TweenerFactors from './Tweener.Factors';
import TweenerOutliner from './Tweener.Outliner';
import TweenerNav from './Tweener.Nav';
import TweenerPlane from './Tweener.Plane';
import TweenerScrollto from './Tweener.Scrollto';
import TweenerService from './Tweener.Service';
import TweenerSuccess from './Tweener.Success';
import TweenerText from './Tweener.Text';
import TweenerTitle from './Tweener.Title';

import $$ from '../toolkit/$$';

/**
 * TweenersController initializer factory.
 *
 * @module TweenersController
 */

class TweenersController {
  /**
   * Creates an instance of TweenersController.
   *
   * @memberof TweenersController
   * @returns An object of initialized TweenersController.
   */

  // A NodeList of '[data-tweener]' HTML Elements:
  public $tweeners: NodeListOf<HTMLElement>;

  // An array of mounted tweeners.
  public tweeners: any[];

  // An array of active tweeners in a queue.
  public tweenersQueue: any[] = [];

  // Default suspend between concurrent tweens.
  public DEFAULT_SUSPEND: number = 350;

  // Default suspend between concurrent tweens.
  public isTweening: boolean = false;

  /**
   * Creates an instance of TweenersController.
   * If there are any `[data-tweener]` in DOM, call `mountTweeners()`.
   *
   * @memberof TweenersController
   */
  constructor() {
    this.$tweeners = $$('[data-tweener]');
    if (this.$tweeners[0]) this.mountTweeners();
  }

  /**
   * Loops through all `$tweeners` and depending on type, call Tweener constructors.
   *
   * @returns {TweenersController} For chaining metods
   * @memberof TweenersController
   */
  mountTweeners() {
    this.beforeMount();

    this.tweeners = [].map
      .call(this.$tweeners, ($tweener, i) => {
        const type = $tweener.dataset.tweener;
        if (type === 'about') return new TweenerAbout($tweener, this, i);
        if (type === 'factors') return new TweenerFactors($tweener, this, i);
        if (type === 'nav') return new TweenerNav($tweener, this, i);
        if (type === 'outliner') return new TweenerOutliner($tweener, this, i);
        if (type === 'plane') return new TweenerPlane($tweener, this, i);
        if (type === 'scrollto') return new TweenerScrollto($tweener, this, i);
        if (type === 'service') return new TweenerService($tweener, this, i);
        if (type === 'success') return new TweenerSuccess($tweener, this, i);
        if (type === 'text') return new TweenerText($tweener, this, i);
        if (type === 'title') return new TweenerTitle($tweener, this, i);
        return new Tweener($tweener, this, i);
      })
      .map((tweener: any) => {
        const watcher = scrollmonitor.create(tweener.$tweener, -30);
        watcher.enterViewport(() => this.onTweenerEnter(tweener, watcher));
        return tweener;
      });

    this.afterMount();

    return this;
  }

  /**
   * Extra function to call BEFORE mounting all tweeners.
   *
   * @returns {TweenersController} For chaining.
   * @memberof TweenersController
   */
  public beforeMount() {
    return this;
  }

  /**
   * Extra function to call AFTER mounting all tweeners.
   *
   * @returns {TweenersController} For chaining.
   * @memberof TweenersController
   */
  public afterMount() {
    return this;
  }

  /**
   * A callback function fired just after tweener.open() function.
   *
   * @param {*} tweener A mounted tweener object.
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public onTweenerStart(tweener) {
    this.isTweening = true;
    return this;
  }

  /**
   * A callback function fired just after tweener.close() function.
   * Controls hashe/href.
   *
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public onTweenerEnd() {
    this.isTweening = false;
    return this;
  }

  /**
   * Watcher callback whi
   *
   * @returns {TweenersController} For chaining.
   * @memberof TweenersController
   */
  public onTweenerEnter(tweener, watcher) {
    watcher.destroy();
    this.tweenersQueue.push(tweener);
    if (this.tweenersQueue.length === 1) this.enqueueTween(tweener);
    return this;
  }

  /**
   * Watcher callback whi
   *
   * @returns {TweenersController} For chaining.
   * @memberof TweenersController
   */
  public enqueueTween(tweener) {
    const tweenerSuspend = tweener.$tweener.dataset.tweenerSuspend;
    const suspend = tweenerSuspend ? Number(tweenerSuspend) : this.DEFAULT_SUSPEND;
    this.tweenersQueue[0].start();
    setTimeout(() => {
      this.tweenersQueue.shift();
      if (this.tweenersQueue.length) this.enqueueTween(this.tweenersQueue[0]);
    }, suspend);
    return this;
  }
}

export default TweenersController;
