const doFixBody = () => {
  document.body.style.paddingRight = `${window.innerWidth - document.body.clientWidth}px`;
  document.body.style.touchAction = 'none';
  document.body.style.overflow = 'hidden';
  document.body.style.pointerEvents = 'none';
};

const doReleaseBody = () => {
  document.body.style.paddingRight = '';
  document.body.style.touchAction = '';
  document.body.style.overflow = '';
  document.body.style.pointerEvents = '';
};

document.addEventListener('body:fix', doFixBody, false);
document.addEventListener('body:release', doReleaseBody, false);

export const fixBody = doFixBody;
export const releaseBody = doReleaseBody;
