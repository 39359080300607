import $$ from '../toolkit/$$';
import scrollTo from '../toolkit/scrollTo';

class ScrollToController {
  $offsetElement: HTMLElement = $$('[data-scrollto-offset-element]')[0];
  $$triggers: NodeListOf<HTMLElement> = $$('[data-scrollto-trigger]');
  $$targets: NodeListOf<HTMLElement> = $$('[data-scrollto]');

  offset: number = 0;

  // Root url.
  public rootUrl: string = document.body.getAttribute('data-root');

  public options: {
    // // Mode, default to `hash`, so URLS are generated with "#". Can be `href`.
    // urlMode?: string;
    // Any extra options
    [option: string]: any;
  };
  constructor(options?: any) {
    this.options = options ? { urlMode: 'hash', ...options } : { urlMode: 'hash' };
    if (this.$offsetElement) this.offset = this.$offsetElement.clientHeight;
    if (this.$$triggers[0]) {
      [].forEach.call(this.$$triggers, ($trigger: HTMLElement) => {
        const $target = $$($trigger.dataset.scrolltoTrigger)[0];
        if ($target) {
          $trigger.addEventListener('click', () => {
            this.doScroll($target, 500);
          });
        }
      });
    }
    if (this.options.urlMode !== 'none') this.checkForScrollTo();
  }

  setActiveTrigger(id: string) {
    const $trigger = $$(`[data-scrollto-trigger="${id}"]`)[0];
    if ($trigger) {
      [].forEach.call(this.$$triggers, ($t: HTMLElement) => {
        $t.classList[$t === $trigger ? 'add' : 'remove']('is-active');
        $t.parentElement.classList[$t === $trigger ? 'add' : 'remove']('is-active');
      });
    }
  }

  doScroll($target: HTMLElement, speed: number = 500) {
    const scrollPos =
      document.documentElement.scrollTop ||
      document.body.parentNode['scrollTop'] ||
      document.body.scrollTop;
    const id = $target.dataset.scrollto;
    const offset = $target.dataset.scrolltoOffset
      ? Number($target.dataset.scrolltoOffset)
      : this.offset;
    const fromTop = $target.getBoundingClientRect().top - offset + scrollPos;
    scrollTo(fromTop, speed, () => {
      this.updateUrl(id);
      if (id) this.setActiveTrigger(id);
    });
  }

  checkForScrollTo() {
    // Exctract needed strings.
    let { href } = window.location;
    const { hash } = window.location;
    // If the last symobol of current URL is '/', remove it.
    if (href[href.length - 1] === '/') href = href.slice(0, -1);
    // If urlMode is 'href', get the last piece of current URL from '/'.
    // Else, if it is hash, just remove '#'.
    const slug = this.options.urlMode === 'href' ? href.split('/').pop() : hash.replace('#', '');
    // Search throught all the modal with '.find()', to find matching 'id'.
    const $target = [].find.call(this.$$targets, $target => $target.dataset.scrollto === slug);
    // If target moadl was found, open it.
    if ($target) this.doScroll($target, 500);

    return this;
  }

  /**
   * Update URL.
   *
   * @returns {ModalsController} For chaining.
   * @memberof ModalsController
   */
  private updateUrl(id) {
    if (this.options.urlMode === 'href') {
      // If URL mode is set to 'href', use history API.
      history.pushState(null, null, `${this.rootUrl}/${id}`);
    } else if (this.options.urlMode === 'hash') {
      // If URL mode is NOT set to set to 'hash', add hash.
      location.hash = `#${id}`;
    }
    return this;
  }
}

export default ScrollToController;
