import Tweener from '../modules/Tweener';

class TweenerService extends Tweener {
  public addTweens() {
    super.addTweens(); // Inherit methods form parent class.

    const $plane = this.$tweener.querySelector('[data-tweener-plane]') as HTMLElement;
    const $media = this.$tweener.querySelector('[data-tweener-media]') as HTMLElement;
    const $icon = this.$tweener.querySelector('[data-tweener-icon]') as HTMLElement;
    const $subtitle = this.$tweener.querySelector('[data-tweener-subtitle]') as HTMLElement;
    const $intro = this.$tweener.querySelector('[data-tweener-intro]') as HTMLElement;
    const $description = this.$tweener.querySelector('[data-tweener-description]') as HTMLElement;
    const align = $media ? $media.dataset.tweenerMedia : null;
    const push = align === 'right' ? -10 : 10;
    const fast = (this.DEFAULT_DURATION * this.SPEED) / 1000;
    // const slow = (this.DEFAULT_DURATION * this.SPEED * 2.5) / 1000;
    const offset = `-=${(this.DEFAULT_OFFSET * this.SPEED) / 1000}`;

    this.addTween($plane, fast, { height: 0 });
    // this.addTween($plane, slow, { width: 2 }, offset);
    this.addTween($plane, fast, { width: 2 }, offset);
    this.addTween($media, fast, { x: push, opacity: 0 }, offset);
    this.addTween($icon, fast, { x: -push, opacity: 0 }, offset);
    this.addTween($subtitle, fast, { x: 10, opacity: 0 }, offset);
    this.addTween($intro, fast, { x: 10, opacity: 0 }, offset);
    this.addTween($description, fast, { x: 10, opacity: 0 }, offset);
    return this;
  }
}

export default TweenerService;
