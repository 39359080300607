import Tweener from '../modules/Tweener';

class TweenerText extends Tweener {
  public addTweens() {
    super.addTweens(); // Inherit methods form parent class.
    const fast = (this.DEFAULT_DURATION * this.SPEED) / 1000;
    this.addTween(this.$tweener, fast, { opacity: 0, x: 50 });
    return this;
  }
}

export default TweenerText;
