import '../scss/index.scss';

import 'masonry-layout';
import 'intersection-observer';
import { TweenMax } from 'gsap/all';
import scrollmonitor from 'scrollmonitor';

import rellax from 'rellax';

// October stuff:
// import * as $ from 'jquery';
// window['$'] = $;
// window['jQuery'] = $;
// import '../js/october/framework.js';
// import '../js/october/framework.extras.js';

import Cookiebar from './modules/Cookiebar';
// import ParallaxsController from './modules/ParallaxsController';
import ScrollToController from './modules/ScrollToController';
import TweenersController from './modules/TweenersController';

import './toolkit/polyfills';
import $$ from './toolkit/$$';
import { fixBody, releaseBody } from './toolkit/fixBody';

const initApp = () => {
  // // initialize cookie notification:
  new Cookiebar();
  // new ParallaxsController();
  new ScrollToController({ urlMode: 'none' });
  // initialize tweens on scroll:
  new TweenersController();

  // watcher header position with '[data-header-observer]'
  if ($$('[data-header-observer]')[0]) {
    new IntersectionObserver(
      (entries, observer) => {
        const { isIntersecting } = entries[0];
        document.body.classList[isIntersecting ? 'remove' : 'add']('header-narrow');
      },
      // { threshold: 1.0 },
    ).observe($$('[data-header-observer]')[0]);
  }

  // const rellax = new Rellax('.rellax');
  if ($$('[data-rellax-speed]')[0] && window.innerWidth > 768) {
    new rellax('[data-rellax-speed]');
    [].forEach.call($$('[data-rellax-speed]'), ($el: HTMLElement) => {
      $el.removeAttribute('data-rellax-speed');
      $el.style['will-change'] = 'transform';
    });
  }

  const $nav = $$('[data-nav]')[0];

  [].forEach.call($$('[data-navicon]'), ($navicon: HTMLElement) => {
    $navicon.addEventListener('click', () => {
      if (document.body.matches('.nav-open')) {
        document.body.classList.remove('nav-open');
        TweenMax.to($nav, 0.36, {
          opacity: 0,
          onComplete: () => {
            $nav.removeAttribute('style');
            releaseBody();
          },
        });
      } else {
        document.body.classList.add('nav-open');
        $nav.style.display = 'flex';
        TweenMax.from($nav, 0.36, { opacity: 0, onComplete: () => fixBody() });
        scrollmonitor.recalculateLocations();
      }
    });
  });

  const $form = $$('[data-protected-form]')[0];

  if ($form) {
    $form.addEventListener('submit', (e: any) => {
      e.preventDefault();
      const $protectedInput: HTMLInputElement = $form.querySelector('[name="code"]');
      const data = {
        code: $protectedInput.value,
        page_id: $form.dataset.protectedForm,
      };

      if ($protectedInput.value) {
        $form.classList.remove('has-error');
        fetch($form.action, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        })
          .then(res => res.json())
          .then((r: any) => {
            if (r.status === 'success') {
              window.scrollTo(0, 0);
              window.location.reload(true);
            } else {
              $form.classList.add('has-error');
            }
          });
      } else {
        $form.classList.add('has-error');
      }
    });
  }
};

window.onload = initApp;
