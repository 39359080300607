import Tweener from '../modules/Tweener';

class TweenerAbout extends Tweener {
  public addTweens() {
    super.addTweens(); // Inherit methods form parent class.
    const $plane = this.$tweener.querySelector('[data-tweener-plane]');
    const $subtitle = this.$tweener.querySelector('[data-tweener-subtitle]');
    const $text = this.$tweener.querySelector('[data-tweener-text]');
    const fast = (this.DEFAULT_DURATION * this.SPEED) / 1000;
    const slow = (this.DEFAULT_DURATION * this.SPEED * 2.5) / 1000;
    const offset = `-=${(this.DEFAULT_OFFSET * this.SPEED) / 1000}`;
    this.addTween($plane, fast, { height: 0 });
    this.addTween($plane, fast, { width: 2 }, offset);
    this.addTween($subtitle, fast, { y: 20, opacity: 0 }, offset);
    this.addTween($text, fast, { y: 30, opacity: 0 }, offset);
    return this;
  }
}

export default TweenerAbout;
