(() => {
  if (typeof (NodeList as any).prototype.forEach !== 'function') {
    (NodeList as any).prototype.forEach = Array.prototype.forEach;
  }
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      (Element.prototype as any).msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }
  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s: any) {
      // tslint:disable-next-line:no-this-assignment
      let el = this;
      do {
        if (el.matches(s)) return el;
        el = (el.parentElement as HTMLElement) || (el.parentNode as HTMLElement);
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }
  return false;
})();
