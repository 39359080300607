import * as Cookies from 'js-cookie';

import $$ from '../toolkit/$$';

class Cookiebar {
  $bar: HTMLElement;
  $accepts: NodeListOf<HTMLElement>;
  hasCookie: boolean;
  constructor() {
    if ($$('[data-cookiebar]')[0]) {
      this.$bar = $$('[data-cookiebar]')[0];
      this.$accepts = $$('[data-cookiebar-accept]');
      this.hasCookie = Boolean(Cookies.get('cookiebar'));
      this.setCookie = this.setCookie.bind(this);
      this.checkCookie();
    }
    return;
  }

  checkCookie() {
    if (this.hasCookie) {
      this.$bar.parentElement.removeChild(this.$bar);
    } else {
      document.body.classList.add('has-cookiebar');
      if (this.$accepts) [].forEach.call(this.$accepts, $a => ($a.onclick = this.setCookie));
    }
  }

  setCookie() {
    Cookies.set('cookiebar', 1);
    document.body.classList.remove('has-cookiebar');
    this.$bar.parentElement.removeChild(this.$bar);
  }
}

export default Cookiebar;
