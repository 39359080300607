import Tweener from '../modules/Tweener';

class TweenerPlane extends Tweener {
  public addTweens() {
    const fast = (this.DEFAULT_DURATION * this.SPEED) / 1000;
    // const slow = (this.DEFAULT_DURATION * this.SPEED * 2.5) / 1000;
    const slow = (this.DEFAULT_DURATION * this.SPEED * 1.5) / 1000;
    super.addTweens(); // Inherit methods form parent class.
    this.addTween(this.$tweener, fast, { height: 0 });
    this.addTween(this.$tweener, slow, { width: 2 });
    return this;
  }
}

export default TweenerPlane;
