/**
 * RAF scrollTop function.
 * Adapted from: https://gist.github.com/james2doyle/5694700
 * easing functions http://goo.gl/5HLl8
 *
 */
/* tslint:disable */

const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

const easeInCubic = (t, b, c, d) => b + c * (t /= d) * t * t;

const inOutQuintic = (t, b, c, d) => {
  const ts = (t /= d) * t,
    tc = ts * t;
  return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc);
};

// requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
const requestAnimFrame = (function() {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window['mozRequestAnimationFrame'] ||
    function(callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

export default function scrollTo(to: number, duration: number, callback?: () => void) {
  // because it's so fucking difficult to detect the scrolling element, just move them all
  function move(amount) {
    document.documentElement.scrollTop = amount;
    document.body.parentNode['scrollTop'] = amount;
    document.body.scrollTop = amount;
  }

  function position() {
    return (
      document.documentElement.scrollTop ||
      document.body.parentNode['scrollTop'] ||
      document.body.scrollTop
    );
  }
  var start = position(),
    change = to - start,
    currentTime = 0,
    increment = 20;
  duration = typeof duration === 'undefined' ? 500 : duration;
  const animateScroll = () => {
    // increment the time
    currentTime += increment;
    // find the value with the quadratic in-out easing function
    var val = easeInOutQuad(currentTime, start, change, duration);
    // move the document.body
    move(val);
    // do the animation unless its over
    if (currentTime < duration) requestAnimFrame(animateScroll);
    else if (callback && typeof callback === 'function') callback();
  };
  animateScroll();
}
