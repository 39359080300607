/**
 * USAGE: kebabize('StringInput')
 * OUTPUT: 'string-input'
 * @param {string} string String in CamelCase format.
 * @returns String in kebab-case format.
 */
export default function kebabize(string: string) {
  return string
    .split(/(?=[A-Z])/)
    .join('-')
    .toLowerCase();
}
