import Tweener from '../modules/Tweener';

class TweenerScrollto extends Tweener {
  public addTweens() {
    super.addTweens(); // Inherit methods form parent class.
    const fast = (this.DEFAULT_DURATION * this.SPEED) / 1000;
    console.log(this.$tweener);
    // this.addTween(this.$tweener, fast, { opacity: 0, y: 30 });
    this.addTween(this.$tweener, fast, { opacity: 0, y: 30 });
    return this;
  }
}

export default TweenerScrollto;
