import Tweener from '../modules/Tweener';

class TweenerNav extends Tweener {
  public addTweens() {
    super.addTweens(); // Inherit methods form parent class.
    const fast = (this.DEFAULT_DURATION * this.SPEED) / 1000;
    this.addTween(this.$tweener, fast, { opacity: 0 });
    return this;
  }
}

export default TweenerNav;
