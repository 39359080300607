import Tweener from '../modules/Tweener';

class TweenerSuccess extends Tweener {
  public addTweens() {
    super.addTweens(); // Inherit methods form parent class.
    const $subtitle = this.$tweener.querySelector('[data-tweener-subtitle]');
    const $description = this.$tweener.querySelector('[data-tweener-description]');
    const fast = (this.DEFAULT_DURATION * this.SPEED) / 1000;
    const offset = `-=${(this.DEFAULT_OFFSET * this.SPEED) / 1000}`;
    this.addTween($subtitle, fast, { y: 20, opacity: 0 });
    this.addTween($description, fast, { y: 30, opacity: 0 }, offset);
    return this;
  }
}

export default TweenerSuccess;
