declare global {
  interface Window {
    $$: any;
  }
}

const $$ = document.querySelectorAll.bind(document);

window.$$ = $$;

export default function (selector: string) {
  if ($$(selector)) {
    return $$(selector);
  }
}
