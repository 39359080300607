import Tweener from '../modules/Tweener';

class TweenerOutliner extends Tweener {
  public $$lines: NodeListOf<HTMLSpanElement>;

  public addTweens() {
    super.addTweens(); // Inherit methods form parent class.

    this.$$lines = this.$tweener.querySelectorAll('[data-tweener-line]');

    const fast = (this.DEFAULT_DURATION * this.SPEED) / 1000;

    [].forEach.call(this.$$lines, ($line: HTMLElement) => {
      const $fill = document.createElement('span');
      $fill.classList.add('outliner__fill');
      $fill.innerText = $line.innerText;
      $line.appendChild($fill);
      $line.removeAttribute('data-tweener-line');
      this.addTween($fill, fast, { height: 0 }, '+=.2');
    });
    return this;
  }

  public onTimelineComplete() {
    super.onTimelineComplete(); // Inherit methods form parent class.

    this.$tweener.classList.remove('_before-outline');
    [].forEach.call(this.$$lines, ($line: HTMLElement) => {
      $line.removeChild($line.querySelector('span'));
    });

    return this;
  }
}

export default TweenerOutliner;
